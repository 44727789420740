import { render, staticRenderFns } from "./uplaodata.vue?vue&type=template&id=f8a937a4&scoped=true&"
import script from "./uplaodata.js?V=20210508?vue&type=script&lang=js&"
export * from "./uplaodata.js?V=20210508?vue&type=script&lang=js&"
import style0 from "./uplaodata.css?vue&type=style&index=0&id=f8a937a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8a937a4",
  null
  
)

export default component.exports